<template>
  <FocusTrap>
    <div class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
        <h5 v-if="project.id == 0" class="card-title">Project Creation</h5>
        <h5 v-if="project.id > 0" class="card-title"> Project Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>


      <div class="card-body">
        <p/>
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input  id="txtname" ref="txtname"  type="text" class="form-control" placeholder="Enter Project Name here..." maxlength="100" v-if="project" v-model="project.name" autocomplete="off" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Type:</label>
          <div class="col-md-10">
            <select class="form-control" required="" v-if="project" v-model="project.type">
              <option value="1" selected="selected">Capital</option>
              <option value="3">Revenue</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Print Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="project" v-model="project.print_name">
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
export default {
  name: 'ProjectForm',
  data () {
    return {
      project: JSON.parse('{"id":0,"type":0,"name":"","print_name":"","project_id":1}')
    }
  },
  props: {
    myproject: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"type":0,"name":"","print_name":"","project_id":1}')
    }
  },
  beforeMount () {
    this.project = this.myproject; // save props data to itself's data and deal with tms
  },

  component: {

  },
  created () {
    this.$data.project = JSON.parse('{"id":0,"type":0,"name":"","print_name":"","project_id":1}')
  },
  mounted () {
    const self = this;
    //$('#txtname').focus();
    self.$refs.txtname.focus();
  },
  methods: {
    closeWindow(){
      this.$emit('project_window_closed');

      if(this.project.id > 1){
        this.$modal.hide('project_window');
        this.project = JSON.parse('{"id":0,"type":0,"name":"","print_name":"","project_id":1}');
      }else{
        this.$router.push("/")
      }
    },
    clear(){
      this.$data.project = JSON.parse('{"id":0,"type":0,"name":"","print_name":"","project_id":1}')
    },

    saveDocument(){
      const self = this;

      if(self.$data.project.name.trim().length < 1){
        alert("Invalid Name");
        return
      }

      self.$data.project.type = parseInt(self.$data.project.type);

      const requestOptions = {
        method:  (self.$data.project.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.project)
      };

      // console.log(JSON.stringify(self.$data.project));

      fetch(`${process.env.VUE_APP_ROOT_API}api/project`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$emit('project_saved', resp.data);
          swal ( { title: "Success!" ,  text: resp.msg, type: "success"} );
          self.project = JSON.parse('{"id":0,"type":0,"name":"","print_name":"","project_id":1}');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"});
      });
    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
